import React, { Dispatch, useCallback, useContext, useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap"
import { useDispatch } from "react-redux"
import IPage from "../../interfaces/page"
import { setCurrentPage } from "../../store/actionCreators"
import { TodayStories } from "../../components/story/story-today.component"
import { useTraceUpdate } from "../../components/_helper/utilities"
import AllPublishedStories from "../../components/story/story-published-all.component"
import instance from "../../api/api"
import { useHistory, useParams } from "react-router-dom"
import Help from "../../components/_helper/help.component"
import StoryVisuals from "../../components/story/story-visuals.component"
import StoryLifelessons from "../../components/story/story-lifelessons.component"
import TabSlider from '../../components/menu-items/tab-slider';
import AccessModal from "../../components/_helper/access-modal.component";
import { Context } from "../home.page";
import { useTranslation } from "react-i18next";

/**
 * Basic enum for navigation
 * export it to be able to pass it down for routing
 */
export enum storyPageMenu {
    TODAY = "1",
    ALL = "2",
    VISUALS = "3",
    LIFE_LESSONS = "4",
}

interface RouteParams {
    page: string
}

/**
 *
 * @param props : props
 * @returns void
 */
const Stories: React.FunctionComponent<IPage> = (props) => {

    const [ accessModal, setAccessModal ] = useState<boolean>(false);

    const [ numOfStories, setNumOfStories ] = useState<number>(0);

    const dispatch: Dispatch<any> = useDispatch();
    const setPageName = useCallback((currentPage: string) => dispatch(setCurrentPage(currentPage)), [dispatch]);

    //Tabs
    const params = useParams<RouteParams>();

    //user
    const userData = useContext(Context).user;
    const updateUserData = useContext(Context).setUserDetails;    

    useTraceUpdate(props);
    const history = useHistory();

    const { t } = useTranslation();    

    useEffect(() => {
        setPageName(t("Stories"));
        if(params.page) {
            setActiveTab(params.page)
        }
        /*
        setTimeout(() => {
            updateLastVisitedTimeForUser();
        }, 10);
        */
        fetchPublishedStories()
    }, []);
/*
    async function updateLastVisitedTimeForUser() {
        await instance.put("/user/" + localStorage.userid, { details: { lastVisited: new Date() } });
    }
*/
    async function fetchPublishedStories() {
        try {
            //Get all accessible stories to know if "View All Stories" button should be shown or not
            //TODO: medium: optimize! Called twice, in AllPublishedStories too
            const newStories = await instance.get(`/publishedstories?pageNum=0`);
            setNumOfStories(newStories.data.totalRecords)
        } catch (error) {
            console.log("Failed to get published stories")
        }
    }    

    function handleRouteChange(route: string) {
        // for the mobile tabs to work
        setActiveTab(route);
        history.push("/dashboard/stories/" + route);
    }

    // for checking window width to toggle mobile tabs navigation
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
    const MAX_WIDTH = 400;
    const checkWindowWidth = (condition: string = "lte", threshold: number = MAX_WIDTH) => (condition === "lte" ? windowWidth <= threshold : windowWidth > threshold);
    const detectSize = () => { setWindowWidth(window.innerWidth); };

    useEffect(() => {
        window.addEventListener("resize", detectSize);
        return () => { window.removeEventListener("resize", detectSize); };
    }, [windowWidth]);

    const [activeTab, setActiveTab] = useState<string>("1");
    const isTabActive = (tabName: string) => (activeTab === tabName ? "active" : "");

    function checkAccess(route:string) {

        if(!userData.subscription) {
            setAccessModal(true);
        }
        else {
            handleRouteChange(route)
        }
    }

    function handleNumAccessibleStories(numberOfRecords:number){
        setNumOfStories(numberOfRecords)
    }

    return (
        <div className="stories-container">
            <TabSlider checkWindowWidth={checkWindowWidth} maxWidth={MAX_WIDTH}>
                <li className={isTabActive(storyPageMenu.TODAY)} onClick={() => { handleRouteChange(storyPageMenu.TODAY); }}>{t("Today")}</li>
                <li className={isTabActive(storyPageMenu.ALL)} onClick={() => { handleRouteChange(storyPageMenu.ALL); }}>{t("All")}</li>
                <li className={isTabActive(storyPageMenu.VISUALS)} onClick={() => { checkAccess(storyPageMenu.VISUALS); }}>{t("Visuals")}</li>
                <li className={isTabActive(storyPageMenu.LIFE_LESSONS)} onClick={() => { checkAccess(storyPageMenu.LIFE_LESSONS); }}>{t("Life Lessons")}</li>
            </TabSlider>
            {checkWindowWidth() && isTabActive(storyPageMenu.TODAY) && (<><TodayStories numOfStories={numOfStories} handleRouteChange={handleRouteChange}/><Help category={"STORIES"} pageName={"Today"}></Help></>)}
            {checkWindowWidth() && isTabActive(storyPageMenu.ALL) && (<><AllPublishedStories /><Help category={"STORIES"} pageName={"All"}></Help></>)}
            {checkWindowWidth() && isTabActive(storyPageMenu.VISUALS) && (<><StoryVisuals /><Help category={"STORIES"} pageName={"Visuals"}></Help></>)}
            {checkWindowWidth() && isTabActive(storyPageMenu.LIFE_LESSONS) && (<><StoryLifelessons /><Help category={"STORIES"} pageName={"Life Lessons"}></Help></>)}

            {checkWindowWidth("gt") &&
            (<Tabs activeKey={params.page} defaultActiveKey={params.page} onSelect={(k) => { k === "3" || k === "4" ? checkAccess(k as string) : handleRouteChange(k as string) }} className="menu-tabs tabs-stories">
                <Tab eventKey={"1"} title={t("Today")}>
                    <TodayStories numOfStories={numOfStories} handleRouteChange={handleRouteChange}/>
                    <Help category={"STORIES"} pageName={"Today"}></Help>
                </Tab>
                <Tab eventKey={"2"} title={t("All")}>
                    <AllPublishedStories />
                    <Help category={"STORIES"} pageName={"All"}></Help>
                </Tab>
                <Tab eventKey={"3"} title={t("Visuals")}>
                    <StoryVisuals />
                    <Help category={"STORIES"} pageName={"Visuals"}></Help>
                </Tab>
                <Tab eventKey={"4"} title={t("Life Lessons")}>
                    <StoryLifelessons />
                    <Help category={"STORIES"} pageName={"Life Lessons"}></Help>
                </Tab>
            </Tabs>
            )}
            <AccessModal show={accessModal} onHide={() => setAccessModal(false)}/>
        </div>
    );
};

export default Stories
