import React, { Dispatch, useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { setCurrentPage } from "../../../store/actionCreators"
import Help from "../../../components/_helper/help.component"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import HubspotContactModal from "../../../components/settings/hubspot-contact.modal"
import { Row, Col } from "react-bootstrap"
import Icon from "@mdi/react"
import { mdiChevronRight } from "@mdi/js"

/**
 * /settings/newsletters page
 *
 */

function EmailAndNewsLetters() {
    const [showHubspotModal, setShowHubspotModal] = useState(false)

    const dispatch: Dispatch<any> = useDispatch()
    const setPageName = useCallback((currentPage: string) => dispatch(setCurrentPage(currentPage)), [dispatch])

    const { t } = useTranslation()

    useEffect(() => {
        setPageName(t("Email & Newsletter"))
    }, [])

    return (
        <div className="settings email-newsletters details-container">
            <div className="settings-container-newsletters">
                <div className="setting-group-row">
                    <p className="setting-group-title">{t("Email & Newsletter")}</p>
                </div>
                <Row className="setting-option-row" onClick={() => setShowHubspotModal(true)}>
                    <Col xs={11}>
                        <p className="settings-title">{t("Family Newsletter")}</p>
                        <p className="settings-description">
                            {t("We dedicate our newsletter to all things 'family'. Sharing Simirity updates and exploring new ideas for your family.")}{" "}
                            <Link to="/dashboard/terms">{t("Terms & Privacy")}</Link>.
                        </p>
                    </Col>
                    <Col className="icon-container" xs={1}>
                        <Icon className="right-icon" size={1.5} path={mdiChevronRight} />
                    </Col>
                </Row>

                <HubspotContactModal show={showHubspotModal} close={() => setShowHubspotModal(false)} />
            </div>
            <Help category={"SETTINGS"} pageName={"Email_Newsletters"}></Help>
        </div>
    )
}

export default EmailAndNewsLetters
