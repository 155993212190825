import React, { useContext, useEffect, useState } from "react"
import { blockTypes, IStory, IBlock } from "../../interfaces/story"
import default_avatar from "../../assets/images/default-avatar.jpg"
import { IUserDetails } from "../../interfaces/authentication"
import Icon from "@mdi/react"
import { mdiCameraOutline, mdiVolumeHigh, mdiCommentTextOutline, mdiEyeOutline, mdiSquare, mdiViewDashboard, mdiMovieOutline, mdiDotsHorizontal } from "@mdi/js"
import { useHistory } from "react-router"
import instance from "../../api/api"
import UserTips from "./usertips.component"
import {  welcomeTypes } from "../../_helper/enum/enum"
import gold_flag from "../../assets/images/bg-flag-gold.svg";
import green_flag from "../../assets/images/bg-flag-green.svg";
import { ContentState, convertFromHTML, EditorState } from "draft-js"
import TooltipHelp from "../notifications/tooltiphelp.component"
import VideoPlayer from "../_helper/video-player.component"
import Masonry from 'react-masonry-css'
import StoryActionModal from "./story-action-modal.component"
import { Context } from "../../pages/home.page"
import axios from "axios"
import config from "../../config/config"
//import EngageGame from "../games/engage-game.component"
import WelcomeGame from "../games/welcome-game.component"
import { IRequest } from "./requests/story-recieved-requests.component"
import ExampleStory from "./example-story.component"
import { useTranslation } from 'react-i18next';
import { newTooltip, unReadTooltip } from "./story-published-all.component"
import commentAPIInstance from "../../api/comment_api"
import { ECommentType } from "../comments/comments-container.component"
import { ReactComponent as IconLifelesson } from "../../assets/images/icon-life_lesson.svg";
import { getStoryDate } from "../../_helper/getStoryDate"
import { storyPageMenu } from "../../pages/dashboard/stories.page"
import IFrameDisplay from "../_helper/iframe-display.component"
import { Spinner } from "react-bootstrap"

interface IProps {
    numOfStories: number
    handleRouteChange : Function
}

enum layoutStyle {
    DASHBOARD="1",
    SINGLE="2"
}

interface ITodayStories {
    new: IStory[],
    unread: IStory[],
    favourite: IStory[],
    own: IStory[],
    active: IStory[],
    inactive: IStory[],
    rest: IStory[]
}

const default_today_stories: ITodayStories = {
    new: [],
    unread: [],
    favourite: [],
    own: [],
    active: [],
    inactive: [],
    rest: []
}

enum StoryType {
    NEW="0",
    UNREAD="1",
    FAVOURITE="2",
    OWN="3",
    ACTIVE="4",
    INACTIVE="5",
    REST="6",
}

interface IGenericObject {
    [key: string]:number
}

/**
 * Have to check this React.memo out properly.
 */
export const TodayStories = React.memo((props:IProps) => {

    const history = useHistory();
    const [ publishedStories, setPublishedStories ] = useState<ITodayStories>(default_today_stories);
    const [ allStories, setAllStories ] = useState<IStory[]>([]);
    const [ layout, setLayout ] = useState<layoutStyle>(layoutStyle.SINGLE);
    const [ selected, setSelected ] = useState<IStory | boolean>(false);
    const [ userTips, setUserTips ] = useState<any>([]);

    //comments
    const [ storyComments, setStoryComments ] = useState<IGenericObject>({})

    //TOOLTIPS
    const [showNew, setShowNew] = useState<boolean>(false);
    const [showUnRead, setShowUnRead] = useState<boolean>(false);

    //Games
    const [ myPublishedStories, setMyPublishedStories ] = useState<IStory[]>([]);
    const [ myStories, setMyStories ] = useState<IStory[]>([]);
    const [ mySentRequests, setMySentRequests ] = useState<IRequest[]>([]); 
    
    //Loaded
    const [ mounted, setMounted ] = useState<boolean>(false);
    const [ mountedNoLoggedIn, setMountedNoLoggedIn ] = useState<boolean>(false);    
    const [ loadedMyPublishedStories, setLoadedMyPublishedStories ] = useState<boolean>(false);
    const [ loadedUserTips, setLoadedUserTips ] = useState<boolean>(false);
    const [ loaded, setLoaded ] = useState<boolean>(false);    

    //Logged in user
    const loggedInUserData = useContext(Context).user;
    //Logged in user no db data
    const loggedInUserNoDb = useContext(Context).userNoDb;
    
    const { t } = useTranslation();

    useEffect(() => {
        fetchPublishedStories();
        fetchUserTips();
        setMountedNoLoggedIn(true)        
    }, [])

    useEffect(() => {
        if(loggedInUserData._id.length) {
            fetchMyPublishedStories();
            fetchMyStories();
            fetchMySentRequests();
            setMounted(true)
        }
    }, [loggedInUserData])
    
    useEffect(() => {
        if(mounted) {
            setLoadedMyPublishedStories(true);
        }
    }, [myPublishedStories])

    useEffect(() => {
        if(mountedNoLoggedIn) {
            setLoadedUserTips(true);
        }
    }, [userTips])

    useEffect(() => {
        if(loadedUserTips && loadedMyPublishedStories) {
            setLoaded(true);
        }
    }, [loadedUserTips, loadedMyPublishedStories])

    function getBlockData(blocks:IBlock[], id:string) {
        const textBlock: IBlock | undefined = blocks.find(x => x.type === blockTypes.TEXT);
        const imageBlock: IBlock | undefined = blocks.find(x => x.type === blockTypes.IMAGE);
        const videoBlock: IBlock | undefined = blocks.find(x => x.type === blockTypes.VIDEO);
        const lifeLessonBlock: IBlock | undefined = blocks.find(x => x.type === blockTypes.LIFE_LESSON);
        const carouselBlock: IBlock | undefined = blocks.find(x => x.type === blockTypes.CAROUSEL);

        let refactoredText = "";
        if(textBlock) {
            const blocksFromHTML = convertFromHTML(textBlock.data);
            const state = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
            );
            const data = EditorState.createWithContent(state)
            refactoredText = data.getCurrentContent().getPlainText().replace('\n','') // getPlainText('\u0001')
        }
        // refactoredText = 300 is a fallback, a CSS trick provides 3 lines of text

        let refactoredLifeLesson = "";
        if(lifeLessonBlock) {
            const blocksFromHTML = convertFromHTML(lifeLessonBlock.data);
            const state = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
            );
            const data = EditorState.createWithContent(state)
            refactoredLifeLesson = data.getCurrentContent().getPlainText().replace('\n','') // getPlainText('\u0001')
        }        

        return(
            <div className="content">
                {lifeLessonBlock && !textBlock && !imageBlock && !videoBlock ? <div className="display-block-lifelesson">
                    <div className="icon">
                        <IconLifelesson />
                    </div>
                    <div className="container">                    
                        {refactoredLifeLesson}                
                    </div>                        
                </div> : null}
                {textBlock ? <p>{refactoredText.substring(0,300)}{refactoredText.length > 300 ? <span>... <a href={`/dashboard/story/${id}`}>More</a></span> : null} </p> : null}
                {videoBlock && <div className="video-content" onClick={(e) => e.stopPropagation() }><VideoPlayer url={videoBlock.media?.data}/></div>}
                {(imageBlock && !videoBlock) ? <img alt={imageBlock._id} src={imageBlock.media?.dataM ? imageBlock.media?.dataM : imageBlock.media?.data} /> : null}
                {(carouselBlock && carouselBlock.multipleMedia.length) ? <img alt={carouselBlock.multipleMedia[0]._id} src={carouselBlock.multipleMedia[0].data}/> : null}
            </div>
        );

    }


    //TODO: Talk about this method
    async function fetchPublishedStories() {
        try {
            //Today
            const todayRes = await instance.get(`/publishedstories/today`);
            setPublishedStories(todayRes.data);
            let all:IStory[] = [];

            Object.values(todayRes.data).forEach(x => {
                all = [...all, ...(x as IStory[])]
            })
            
            all.forEach(story => {
                fetchCommentData(story);
            })
            setAllStories(all);
            //console.log("DONE: fetchPublishedStories")

        } catch (error) {
            console.log("Failed to get published stories", error)
        }
    }

    // to check if there is any published story I created
    async function fetchMyPublishedStories() {
        try {
            const myPublishedStories = await instance.get(`/publishedstories?storyAuthorCoAuthor=${loggedInUserData._id}`);
            setMyPublishedStories(myPublishedStories.data);
            //console.log("DONE: fetchMyPublishedStories")
        } catch (error) {
            console.log("Failed to get published stories", error)
        }
    }

    // to check if there is a co-author for any of my stories
    async function fetchMyStories() {
        try {
            const myStories = await instance.get(`/stories`);
            setMyStories(myStories.data);
            //console.log("DONE: fetchMyStories")            
        } catch (error) {
            console.log("Failed to get published stories", error)
        }
    }
    
    async function fetchMySentRequests() {
        try {
            const myRequests = await instance.get(`/requests?from=${loggedInUserData._id}`);
            setMySentRequests(myRequests.data);
            //console.log("DONE: fetchMySentRequests")
        }
        catch(error) {
            console.log("Failed to get requests", error)
        }
    }    

    async function fetchUserTips() {        
        await axios.get(`${config.contentServerURL}/api/user-tips?sort[0]=sortOrder:asc`).then(resp => { // Strapi v4 query format
            setUserTips(resp.data.data);
            //console.log("DONE: fetchUserTips")
        }, error => {
            console.log("Failed to get user tips", error)
        });
    }

    async function fetchCommentData(story:IStory) {
        try {
            const res = await commentAPIInstance.get("/", { 
                params: { 
                    pageNo:1,
                    query :{
                        related_entity:story._id,
                        comment_type:ECommentType.STORY
                    }
                },
                headers: {"Authorization" : `Bearer ${story.accessCommentToken}`}
            })
            setStoryComments(prev => { return {...prev, [story._id]:res.data.totalRecords} })
            //console.log("DONE: fetchCommentData")
        }
        catch(error) {
            console.log('Comments could not be fetched', error)
        }
    }

    function displayStory(story:IStory, type:StoryType) {
        const imageBlocks = story.blocks.filter(x => x.type === blockTypes.IMAGE).length;
        const audioBlocks = story.blocks.filter(x => x.type === blockTypes.AUDIO).length;
        const videoBlocks = story.blocks.filter(x => x.type === blockTypes.VIDEO).length;
        const carouselBlocks = story.blocks.filter(x => x.type === blockTypes.CAROUSEL);

        const yearsPassed = new Date().getFullYear() - story.date.startDate.year;
        const isPeriod = story.date.endDate ? true : false;

        return (
            <div key={story._id} className="story-item-container">
                <div className="story-item-content" onClick={() => history.push(`/dashboard/story/${story._id}`)}>
                    <div className="story-item-header">
                        {type === StoryType.NEW ? <div className="flair" onClick={(e) => { e.stopPropagation(); setShowNew(true)}}>
                            <img alt="flag" src={green_flag}/>
                        </div> : null}
                        {type === StoryType.UNREAD ? <div className="flair" onClick={(e) => { e.stopPropagation(); setShowUnRead(true)}}>
                            <img alt="flag" src={gold_flag}/>
                        </div> : null}
                        <p>{type === StoryType.NEW ? t("Newly Published") : (yearsPassed === 0 && !isPeriod) ? t("Today") : (yearsPassed > 0 && !isPeriod) ? t("{{var}} years ago, Today", {var: yearsPassed}) : (yearsPassed === 0 && isPeriod) ? t("Starting Today") : (yearsPassed > 0 && isPeriod) ? t("{{var}} years ago, starting today", {var: yearsPassed}) : ""}</p>
                        <div className="action-container" onClick={(e) => { e.stopPropagation(); setSelected(story);}}>
                            <Icon className="action" size={1} path={ mdiDotsHorizontal } />
                        </div>
                    </div>
                    <div className="story-item-info">
                        <h3 className="story-item-title">{story.title}</h3>
                        <img src={getUserImage(story)} className="user-picture" alt="user" />
                        <p className="story-item-author">
                            <span className="user-name">{`${(story.featured as IUserDetails).firstname} ${(story.featured as IUserDetails).lastname}`}</span>
                            <span className="story-date">
                                {getStoryDate(story.date.startDate)} {story.date.endDate && <span> &nbsp;-&nbsp; { getStoryDate(story.date.endDate)}</span>}
                            </span>
                        </p>
                    </div>
                    <div  className="story-blocks">
                        {getBlockData(story.blocks, story._id ? story._id : "")}
                    </div>
                    <div className="story-item-footer">
                        <div className="toolbar">
                            {imageBlocks ? <span title="image"><Icon className="icon" size={0.8} path={mdiCameraOutline} />{imageBlocks}</span> : null}
                            {videoBlocks ? <span title="video"><Icon className="icon" size={0.8} path={mdiMovieOutline} />{videoBlocks}</span> : null}
                            {audioBlocks ? <span title="audio"><Icon className="icon" size={0.8} path={mdiVolumeHigh} />{audioBlocks}</span> : null}
                            {carouselBlocks && carouselBlocks.length ? <span title="image"><Icon className="icon" size={0.8} path={mdiCameraOutline} />{carouselBlocks[0].multipleMedia.length}</span> : null}
                            {(storyComments.hasOwnProperty(story._id) && storyComments[story._id] > 0) ? <span title="comments"><Icon className="icon" size={0.8} path={mdiCommentTextOutline} />{storyComments[story._id]}</span> : null}
                        </div>
                        <div className="readtime">
                            <span title="read time"><Icon className="icon" size={0.8} path={mdiEyeOutline} />{story.readTime} {t("Min")}</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function getUserImage(story:IStory) {
        const featured = story.featured as IUserDetails
        return featured.avatar ? featured.avatar : default_avatar
    }

    function loadGame(){

        // if no GAME turned on at user settings then just return
        if(!(loggedInUserData && loggedInUserData.settings)) { 
            return null
        }

        if(!(loggedInUserData.settings.games.welcome || loggedInUserData.settings.games.engage)) {
            return null
        }

        // remove now
        const now = new Date();
        const welcomeTodayGame = (loggedInUserNoDb.welcomeTodayGame === null || loggedInUserNoDb.welcomeTodayGame.getDate() - now.getDate() > 1) ? true : false;
        const engageTodayGame = (loggedInUserNoDb.engageTodayGame === null || loggedInUserNoDb.engageTodayGame.getDate() - now.getDate() > 1) ? true : false;
         
        // welcome
        const hasStory = myPublishedStories.length ? true : false;
        const hasFamily = loggedInUserData.relations.length ? true : false;
        const hasStoryRequest = mySentRequests.length ? true : false;

        // engage
        const inspirationClicked = loggedInUserData.settings.other ? (loggedInUserData.settings.other.filter( x => x==="inspirationClicked").length ? true : false) : false;
        const hasCoAuthor = myStories.filter(x=>x.coAuthors.length).length ? true : false;

        if(loggedInUserData.settings.games.welcome && !(hasStory && hasFamily && hasStoryRequest)){
            return (welcomeTodayGame ? <WelcomeGame welcomeType={welcomeTypes.WELCOME} completed={[hasStory, hasFamily, hasStoryRequest]}/> : null)
        }
        
        /* 2023.06.22.
        // precedence 1.: welcome game
        if(loggedInUserData.settings.games.welcome && !(hasStory && hasFamily && hasStoryRequest)) {
            return (welcomeTodayGame ? <WelcomeGame welcomeType={welcomeTypes.WELCOME} completed={[hasStory, hasFamily, hasStoryRequest]}/> : null)
        }

        // precedence 2.: engage game
        if(loggedInUserData.settings.games.engage && !(inspirationClicked && hasStoryRequest && hasCoAuthor)) {
            return (engageTodayGame ? <EngageGame welcomeType={welcomeTypes.ENGAGE} completed={[inspirationClicked, hasStoryRequest, hasCoAuthor]}/> : null)
        }
        */
    }  

    return (
        <div className="today-container">
            {!loaded ?
                <div className="spinner-container">
                    <Spinner animation="border" variant="#fff" />
                </div>
            :
            <div>
                {loadGame()}
                {myPublishedStories.length ? null : <ExampleStory />}
                <div className="today-container-heading">
                    {allStories.length ?
                        <p className="today-heading">{t("Newly Published & Story Anniversaries")} ({allStories && allStories.length})</p>
                    : props.numOfStories > 0 ?   
                        <div>
                            <p className="today-heading">{t("No stories today")}</p>
                            <button className="default-button button-space" onClick={() => props.handleRouteChange(storyPageMenu.ALL)}>{t("View all stories")}</button>
                            <IFrameDisplay pageName={"stories-today"} />                            
                        </div>
                    : null}
                    {allStories.length ? <div className="layout-change">
                        <span onClick={() => setLayout(layoutStyle.SINGLE)}><Icon className={`icon ${layout === layoutStyle.SINGLE ? "active" : ""}`} size={1} path={mdiSquare} /></span>
                        <span onClick={() => setLayout(layoutStyle.DASHBOARD)}><Icon className={`icon ${layout === layoutStyle.DASHBOARD ? "active" : ""}`} size={1} path={mdiViewDashboard} /></span>
                    </div> : null}
                </div>
                <div className={layout === layoutStyle.SINGLE ? "story-items" : "story-items dashboard"}>
                    <Masonry
                        breakpointCols={layout === layoutStyle.SINGLE ? 1 : 3}
                        className="my-masonry-grid"
                        columnClassName="my-masonry-grid_column">
                        {publishedStories.new.length ?
                            publishedStories.new.map((story) => {
                                return displayStory(story, StoryType.NEW)
                            }) 
                        : null}

                        {publishedStories.unread.length ?
                            publishedStories.unread.map((story) => {
                                return displayStory(story, StoryType.UNREAD)
                            })
                        : null}

                        {publishedStories.favourite.length ?
                            publishedStories.favourite.map((story) => {
                                return displayStory(story, StoryType.FAVOURITE)
                            })
                        : null}

                        {publishedStories.own.length ?
                            publishedStories.own.map((story) => {
                                return displayStory(story, StoryType.OWN)
                            })
                        : null}

                        {publishedStories.active.length ?
                            publishedStories.active.map((story) => {
                                return displayStory(story, StoryType.ACTIVE)
                            })
                        : null}

                        {publishedStories.inactive.length ?
                            publishedStories.active.map((story) => {
                                return displayStory(story, StoryType.INACTIVE)
                            })
                        : null}

                        {publishedStories.rest.length ?
                            publishedStories.active.map((story) => {
                                return displayStory(story, StoryType.REST)
                            })
                        : null}
                    </Masonry>
                </div>
                <TooltipHelp
                    title={newTooltip.title}
                    subtitle={newTooltip.subtitle}
                    text={newTooltip.text}
                    show={showNew}
                    clear={() => setShowNew(false)}
                />
                <TooltipHelp
                    title={unReadTooltip.title}
                    subtitle={unReadTooltip.subtitle}
                    text={unReadTooltip.text}
                    show={showUnRead}
                    clear={() => setShowUnRead(false)}
                />
                {selected &&  <StoryActionModal story={(selected as IStory)} author={((selected as IStory).author as IUserDetails)._id === loggedInUserData._id || (selected as IStory).coAuthors.map(x => (x as IUserDetails)._id).includes(loggedInUserData._id)} show={selected ? true : false} onHide={() => setSelected(false)} redirect={() =>  history.push('/dashboard/create', {data: (selected as IStory)})}/>}
                {!(loaded) ? null : <UserTips data={userTips} />}
            </div>
            }
        </div>
    )
});