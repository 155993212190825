import React, { useState, useEffect, useRef, useContext } from "react";
import instance from "../../api/api";
import { IUserDetails } from "../../interfaces/authentication";
import { fileTypes, IMedia } from "../../interfaces/story";
//import { Spinner } from "react-bootstrap";
import ProfileVisualsFiltersBar, { EFilterBarContainer } from "./profile-visuals-filter-bar.component";
//import ProfileVoiceDetails from "./profile-voice-details";
import moment from "moment";
import { dateFormat } from "../../_helper/enum/enum";
import default_avatar from "../../assets/images/default-avatar.jpg";
import WaveSurfer from 'wavesurfer.js';
import Icon from "@mdi/react";
import { mdiPause, mdiPlay, mdiVolumeHigh } from "@mdi/js";
import { ESubscriptionStatus } from "../../interfaces/subscription";
import { useHistory } from "react-router-dom";
import { Context } from "../../pages/home.page";
import IFrameDisplay from "../_helper/iframe-display.component";
import { useTranslation } from "react-i18next";

interface IProps {
    user: IUserDetails
    administrator: boolean
    myProfile: boolean
}

interface ISort {
    field:string
    type:string
}

export enum profileVoiceSortOptions {
    UPLOAD_DATE="Upload date",
    OLDEST="Oldest story date",
    NEWEST="Newest story date",
}

export default function ProfileVoice(props:IProps) {

    const [ media, setMedia] = useState<IMedia[]>([]);
    const [ hasMore, setHasMore ] = useState<boolean>(false);
    const [ page, setPage ] = useState<number>(0);
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ fav, setFav] = useState<boolean>(false);
    const [ totalCount, setTotalCount ] = useState<number>(0);

    const [ sortOption, setSortOption ] = useState<ISort>({field:'createdAt', type:'desc'});

    const [ sort, setSort ] = useState<profileVoiceSortOptions>(profileVoiceSortOptions.UPLOAD_DATE);

    const [ showVoiceDetails, setShowVoiceDetails ] = useState<boolean>(false);
    const [ visualDetails, setVisualDetails ] = useState<IMedia | null>();

    const history = useHistory()

    const userData = useContext(Context).user;

    const { t } = useTranslation();

    useEffect(() => {
        return () => {
            setMedia([]);
        }
    }, [])

    useEffect(() => {
        if(props.user && props.user._id && props.user._id.length && userData.subscription && userData.subscription.status !== ESubscriptionStatus.INACTIVE && !loading) {
            loadVisuals();
        }
    }, [ sortOption, fav, props.user._id, page, userData.subscription ])


    async function loadVisuals() {
        setLoading(true);
        let queryString = "";
        let sortString = "";

        queryString = `/media?&pageNum=${page}`

        if(fav) {
            queryString += "&favorites=true"
        }

        if(sortOption.field.length) {
            sortString = "sort=" + sortOption.field + "," + sortOption.type;
        }

        const response = await instance.get(queryString + "&" + sortString, {
            params: {
                fileType: [fileTypes.AUDIO],
                user: props.user._id
            }
        });

        setMedia((prev) => { return [...prev, ...response.data.result]});

        setHasMore(response.data.hasNextPage);
        setTotalCount(response.data.totalRecords)
        setLoading(false);
    }

    useEffect(() => {

        switch (sort){
            case profileVoiceSortOptions.UPLOAD_DATE:
                _setSortOption({field:'createdAt', type:'desc'})
                break
            case profileVoiceSortOptions.OLDEST:
                _setSortOption({field:'relatedStory.date.startDate.date', type:'asc'})
                break
            case profileVoiceSortOptions.NEWEST:
                _setSortOption({field:'relatedStory.date.startDate.date', type:'desc'})
                break
        }

    }, [ sort ])

    async function loadMore() {
        setPage(prev => prev+1);
    };

    function previousVoice() {
        const currentIndex = media.findIndex((data) => data._id === visualDetails?._id);
        if(media[currentIndex-1]) {
            setVisualDetails(media[currentIndex-1])
        }
    }

    function nextVoice() {
        const currentIndex = media.findIndex((data) => data._id === visualDetails?._id);
        if(media[currentIndex+1]) {
            setVisualDetails(media[currentIndex+1])
        }
    }

    function _setSortOption(option:any) {
        setMedia([]);
        setPage(0);
        setSortOption(option)
    }

    function _setFav() {
        setMedia([]);
        setPage(0);
        setFav(prev => !prev)
    }

    return(
        <div className="profile-voice-container">
          {(userData.subscription && userData.subscription.status !== ESubscriptionStatus.INACTIVE) ?
          <>
            <ProfileVisualsFiltersBar container={EFilterBarContainer.VOICE} setSort={setSort} sort={sort} setFavourite={_setFav} favourite={fav} />
            <div className="content">
                <div className="info-row">
                    <p>{totalCount} {t("Voices")}</p>
                </div>

                    <div className="voices">
                        {media.map((media, index) => {                            
                                return(
                                    // <div key={index} className="voice-container" onClick={() => { setShowVoiceDetails(true); setVisualDetails(media)}}>
                                    <div key={index} className="voice-container" onClick={() => history.push(`/dashboard/story/${media.relatedStory._id}`)}>
                                        <div>
                                            <div className="from">
                                                <p className="text">{t("From")}</p>
                                                <p className="story-title">{media.relatedStory.title}</p>
                                            </div>
                                            <div className="voice-player">
                                                <LocalVoicePlayer media={media} index={index}/>
                                            </div>
                                        </div>
                                        <p className="media-title">{media.description ? media.description : t("Unknown Title")}</p>
                                        <div className="profile-and-date">
                                            <div className="image">
                                                <img className="user-picture" alt="profile-avatar" src={media.user.avatar ? media.user.avatar : default_avatar}/>
                                                <p>{media.user.nickname ? media.user.nickname : media.user.firstname}</p>
                                            </div>
                                            <p className="date">{moment(media.relatedStory.date.startDate.date).format(dateFormat.SHORT)}</p>
                                        </div>
                                    </div>
                                )
                            })}
                    {!hasMore && media.length ? <p className="has-more">{t("No more voices to display")}</p> : null}
                    </div>
            </div>
            {!media.length && <IFrameDisplay pageName={"my_profile-voice"} />}            
            </>
            : <p className="sub-access-message">{t("You have to be a subscriber to access this page")}</p>}
        </div>
    )
}

interface IVoiceProps {
    media:IMedia
    index:number
}

function LocalVoicePlayer(props: IVoiceProps) {

    const [loaded, setLoaded] = useState<boolean>(false);

    //Audio elements
    const [waveSurfer, setWaveSurfer] = useState<any>(false);
    const [play, setPlay] = useState<boolean>(false);
    const [pause, setPause] = useState<boolean>(false);

    const wrapperRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        if (wrapperRef.current && !waveSurfer) {
            const audio = new Audio((props.media.data as string));

            const wavesurferLoc = WaveSurfer.create({
                container: '#waveform_' + props.media._id,
                waveColor: 'grey',
                progressColor: 'grey',
                barWidth: 3,
                barGap: 2,
                height: 100
            });
            wavesurferLoc.load(audio);
            setWaveSurfer(wavesurferLoc);
            setLoaded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ ])

    useEffect(() => {
        if (waveSurfer) {
            waveSurfer.backend.on('audioprocess', function (time: any) {
                let current = parseFloat(time.toFixed(1));
                let max = parseFloat(waveSurfer.backend.getDuration().toFixed(1))
                if ((current + 0.2) >= max) {
                    setPlay(false);
                    setPause(false);
                }
            });
        }
        return () => {
            if (waveSurfer) {            
                pauseAudio();
            }
        };          
    }, [waveSurfer])

    function playAudio() {
        waveSurfer.play();
        setPlay(true);
        setPause(false);
    }

    function pauseAudio() {
        waveSurfer.pause();
        setPause(true);
    }

    function decidePlay(e:any) {
        e.stopPropagation();

        if(play && !pause) {
            pauseAudio()
        }else {
            playAudio()
        }
    }

    return (
        <div className="display-audio-interaction">
            <div ref={wrapperRef} className="wave" id={'waveform_' + props.media._id}></div>
            {loaded && <div className={`interaction-container audio ${play ? "played" : ""}`} onClick={(e) => decidePlay(e)}>
                {(play && !pause) ?
                    <Icon size={1} path={mdiPause} />
                    : (play && pause) ?
                        <Icon size={1} path={mdiPlay} />
                        :
                        <Icon size={1} path={mdiVolumeHigh} />
                }
            </div>}
        </div>
    );
}
