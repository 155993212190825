import React, { useContext, useEffect, useState } from "react";
import { mdiHelpCircle, mdiPaperclip } from "@mdi/js";
import Icon from "@mdi/react";
import { Form } from "react-bootstrap";
import { IUserDetails } from "../../../interfaces/authentication";
import { Context } from "../../../pages/home.page";
import { profileTypes } from "../../../_helper/enum/enum";
import TooltipHelp from "../../notifications/tooltiphelp.component";
import UniqueUserListInput from "../../_helper/unique-selects/unique-user-list-input.component";
import { useLocation } from 'react-router-dom';
import instance from "../../../api/api";
import Notification, { notificationImages } from "../../notifications/notification.component";
import { INotification, NotificationType } from "../../../interfaces/notification";
import { useTranslation } from "react-i18next";

interface IProps {
    handleUpdateSentList: () => void
}

interface IRequest {
    title:string
    to:IUserDetails[]
    notes: string
    attachedInspirationQ?:string
    attachedInspirationCat?:string    
    attachedStory?:string
}

export enum navigatedToRequest {
    STORY= "1",
    INSPIRATION= "2"
}

export default function StoryRequestsCreate(props:IProps) {

    const [ request, setRequest ] = useState<IRequest>({title:"", to:[], notes:""});
    const [ showTooltip, setShowTooltip ] = useState<boolean>(false);
    const [ navigatedFrom, setNavigatedFrom ] = useState<boolean | navigatedToRequest>(false);

    const [ success, setSuccess ] = useState<boolean>(false);
    const [ error, setError ] = useState<boolean>(false);

    const loggedInUserData = useContext(Context).user;
    const location: any = useLocation();

    const [isMobile, setIsMobile] = useState(false);
    
    const { t } = useTranslation();

    const successNotificationData: INotification = { icon: notificationImages.like, title: t("Success"), text: t("Request successfully sent"), success: NotificationType.success }
    const errorNotificationData: INotification = { icon: notificationImages.crying, title: t("Failed"), text: t("Failed to send request"), success: NotificationType.problem }

    useEffect(() => {
        if(location.state) {
            //have to get some information from rerouting to be able to fill out query
            location.state.hasOwnProperty('from') ? setNavigatedFrom(location.state.from as navigatedToRequest) : setNavigatedFrom(false);
        }
    }, [location.state])

    //choose the screen size 
    const handleResize = () => {
      if (window.innerWidth < 720) {
          setIsMobile(true)
      } else {
          setIsMobile(false)
      }
    }
    
    // create an event listener
    useEffect(() => {
      window.addEventListener("resize", handleResize);
      handleResize();
    }, [window])


    async function sendRequest() {
        
        try {
            let requestData = {
                ...request,
                from: loggedInUserData._id,
                to: request.to.map(x => x._id)
            }

            if(navigatedFrom === navigatedToRequest.INSPIRATION) {
                requestData.attachedInspirationQ = location.state.id;
                requestData.attachedInspirationCat = location.state.category;
            }
            if(navigatedFrom === navigatedToRequest.STORY) {
                requestData.attachedStory = location.state.id;
            }

            await instance.post("/requests", { data:requestData });
            setSuccess(true);

            setTimeout(() => {
                setSuccess(false);
                setRequest({title:"", to:[], notes:""});
                props.handleUpdateSentList();
            }, 3000)
        }
        catch(err) {
            setError(true);

            setTimeout(() => {
                setError(false);
            }, 3000)
        }
    }

    function checkDisabledButton() {
        return (request.title.length && request.to.length && request.notes.length && !(success || error)) ? false : true
    }

    function updateUsers(selectedData:IUserDetails) {
        if(request.to.filter((data:IUserDetails) => data._id === selectedData._id).length) {
            const temp = request.to.filter((data:IUserDetails) => data._id !== selectedData._id);
            setRequest(prev => { return {...prev, to:temp} });
        }
        else {
            setRequest(prev => { return {...prev, to:[...prev.to, selectedData]} });
        }
    }

    return(
        <div className="story-requests-create">
            <div className="top-info">
                <p className="help-text">{t("Inspire others with a Story Request")}</p>
                <div className="icon-container" onClick={() => setShowTooltip(true)} ><Icon size={1} path={ mdiHelpCircle } /></div>
                <p>{t("Need ideas?")} <a href={"/dashboard/inspiration"}>{t("Explore inspiration")}</a></p>
            </div>
            <Form.Group className="group simple">
                <input placeholder={t("Title")} autoFocus={!isMobile} value={request.title} onChange={(evt) => evt.target.value.length <= 50 ? setRequest(prev => {return {...prev, title:evt.target.value}}) : null } type="text"></input>
                <p className="sub-subtitle right">{request.title.length} / 50</p>
            </Form.Group>
            <Form.Group className="group">
                <UniqueUserListInput placeholder={t("Share with")} handleAction={updateUsers} data={request.to} options={loggedInUserData.relations.filter(x => x.userid.profileType !== profileTypes.DECEASED).map(x => x.userid)} />
            </Form.Group>
            <div>
                <textarea value={request.notes} onChange={(evt) => setRequest(prev => { return { ...prev, notes:evt.target.value }})} name="review-list" placeholder={t("Request details")} rows={5} />
            </div>
            <div className="">
                {navigatedFrom === navigatedToRequest.INSPIRATION ? <div className="navigated-from-info">
                    <div className="clip"><Icon size={1.5} path={ mdiPaperclip } /></div>
                    <p className="info-title">{t("Inspiration")}</p>
                    <p className="info-text">{location.state.data}</p>
                </div> : null}
                {navigatedFrom === navigatedToRequest.STORY ? <div className="navigated-from-info">
                    <div className="clip"><Icon size={1.5} path={ mdiPaperclip } /></div>
                    <p className="info-title">{t("Story")}</p>
                    <p className="info-text">{location.state.data}</p>
                </div> : null}
            </div>
            <button className="default-button" disabled={checkDisabledButton()} onClick={() => sendRequest()}>{t("Send Request")}</button>
            {success ? <Notification data={successNotificationData} close={() => setSuccess(false)}/> : null }
            {error ? <Notification data={errorNotificationData} close={() => setError(false)}/> : null }
            <TooltipHelp contextKey={'story_requests'} clear={() => setShowTooltip(false)} show={showTooltip} />            
        </div>
    );
}